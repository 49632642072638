import { Page } from '../components/Page';
import { SEO }  from '../components/atom/SEO';

export const NotFoundPage = ():JSX.Element => (
     <Page className="page-404">
          <SEO title="404: Not found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
     </Page>
);

export default NotFoundPage;
